span{
  color: #e91185;
}

.hero-container {
  background: #ffffff;
  padding: 200px; 
  padding-bottom: 150px;
  padding-top: 200px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.hero-content {
  max-width: 50%; 
  text-align: left; 
}

.hero-title {
  font-size: 4rem; 
  font-weight: 800; 
  line-height: 1.2; 
}

.hero-subtitle { 
  margin: 40px 0;
  line-height: 1.6; 
  color: #4a4a4a;
}

.hero-button {
  background-color: black; /* Set button background color to black */
  color: white; /* Set button text color to white */
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  display: inline-block;
  transition: background-color 0.3s ease;
}

.hero-button:hover {
  background-color: #e91185;
  color: white;
}

.hero-image {
  max-width: 40%;
  text-align: center;
}

.hero-logo {
  width: 100%;
  height: auto; 
}

/* Responsive styles */
@media (max-width: 896px) {
  .hero-container {
    flex-direction: column;
    padding: 100px 20px; /* Adjust padding for mobile */
  }

  .hero-content {
    max-width: 100%;
    text-align: center; /* Center text for mobile */
  }

  .hero-title {
    font-size: 2.5rem; /* Adjust font size for mobile */
  }

  .hero-subtitle {
    margin: 20px 0;
  }

  .hero-button {
    margin-top: 20px;
  }

  .hero-image {
    margin-top: 20px;
    text-align: center;
  }

  .hero-logo {
    width: 80%; /* Adjust logo size for mobile */
  }
}