.weare-section-bottom {
  background-color: #0E3384;
  padding: 100px 0; 
  text-align: center;
  color: #ffffff; 
}

.weare-title h2 {
  font-size: 3rem; 
  font-weight: 700; 
  margin-bottom: 30px;
  color: #ffffff;
}

.highlight {
  background: -webkit-linear-gradient(#00e5ff, #00c9ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.video-container {
  display: flex;
  justify-content: center; 
}

.youtube-video {
  border: 5px solid #ffffff;
  border-radius: 10px; 
}

/* Add responsive styles for WeAreSectionBottom */
@media (max-width: 896px) {
  .we-are-section {
    padding: 20px;
    text-align: center;
  }

  .we-are-content {
    max-width: 100%;
  }

  .we-are-title {
    font-size: 2rem;
  }

  .we-are-description {
    font-size: 1rem;
  }
}