.contact-us-container {
    padding: 2rem;
    font-family: 'Inter', sans-serif;
  }

  .contact-us-header {
    padding-top: 5rem;
    padding-bottom: 2rem;
  }
  
  .contact-title {
    font-size: 2.5rem;
    font-weight: bold;
  }
  
  .contact-form {
    border-radius: 8px;
  }
  
  .submit-button {
    background-color: #e91185; 
    border: none;
    padding: 0.5rem 1.5rem;
    border-radius: 5px;
    transition: transform 0.2s ease;
  }
  
  .submit-button:hover {
    transform: translateY(-3px);
    background-color: #d11278; 
  }
  
  .map-container {
    border-radius: 8px;
    overflow: hidden;
  }

.social-media {
  margin-top: 20px;
}

.social-media-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px; /* Add vertical gap between items */
  text-decoration: none;
  color: inherit;
  transition: transform 0.2s, box-shadow 0.2s;
}

.social-media-item:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.social-icon {
  font-size: 2rem; /* Make icons bigger */
  color: #ff69b4; /* Use the same shade of pink as in the footer */
  margin-right: 10px;
}

.social-media-item span {
  font-size: 1.2rem;
}

@media (max-width: 896px) {
  .social-media {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
  }

  .social-media-item {
    margin: 0.5rem;
  }

  .social-icon {
    font-size: 2rem; /* Adjust icon size for mobile */
  }
}