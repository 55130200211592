.footer-container {
    background-color: #000;
    color: #fff; 
    text-align: center;
    padding: 60px 0; 
  }
  
  .footer-title {
    font-size: 2rem; 
    font-weight: 700;
    margin-bottom: 15px;
  }
  
  .footer-icons {
    margin: 30px 0; 
  }
  
  .footer-icon {
    color: #e91185;
    margin: 0 15px;
    font-size: 2rem; 
    cursor: pointer;
    transition: 0.3s ease-in-out;
  }
  
  .footer-icon:hover {
    color: #ffffff; 
  }
  
  .footer-text {
    margin-top: 20px;
    font-size: 0.875rem;
    font-weight: 400;
  }

  .footer-link {
    color: white; /* Set link color to white */
    text-decoration: none;
    margin: 0 5px;
    transition: color 0.3s ease;
  }
  
  .footer-link:hover {
    color: #00e5ff;
  }
  
  .footer-container p {
    color: white; /* Set trademark text color to white */
  }
  

  