.partners-header {
    background-color: #e91185;
    color: #fff;
    height: 60vh;
}

.section-title {
    font-size: 3rem;
    font-weight: 800; 
    margin-bottom: 20px;
    margin-top: 10rem;
}

.section-subtitle {
    margin-top: 1rem;
    margin-bottom: 2.5rem;
    color: #fff;
}

.btn-custom {
    padding: 0.5rem 1.5rem;
    font-weight: 700;
    color: black;
    background-color: #00ffff;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.btn-custom2 {
    padding: 0.5rem 1.5rem;
    font-weight: 700;
    color: white;
    background-color: #e91185;
    border: none;
    border-radius: 5px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.btn-custom2:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}

.btn-custom2:focus {
    outline: none;
}

.btn-custom:hover {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
}

.btn-custom:focus {
    outline: none;
}

.partner-card {
    background-color: #fff;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    margin-left: 6rem;
}

.partner-logo-container {
    width: 400px;
    height: auto;
}

.partner-logo {
    max-width: 100%;
    height: auto;
}

.partner-title {
    font-size: 2rem;
    font-weight: bold;
}

.partner-description {
    margin-top: 1.5rem;
}

.partner-info {
    flex: 1;
    margin-right: 4rem;
}

.learn-more-container{
    margin-top: 2rem;
}

.learn-more-btn {
    background-color: #000;
    color: #fff;
    text-decoration: none;
    transition: margin-left 0.3s;
}

.learn-more-btn .arrow {
    font-weight: bold;
    transition: margin-left 0.3s;
}

.learn-more-btn:hover {
    margin-left: 5px; 
}


.cta-section {
    background-color: #0e3384;
    color: #fff;
    height: 50vh;
}

.cta-title {
    font-size: 2rem;
    font-weight: bold;
}

.cta-subtitle {
    color: #fff;
    padding-right: 15rem;
    padding-left: 15rem;
    margin-top: 4rem;
}

.cta-btn {
    background-color: #e91185;
    color: #fff;
    border: none;
    padding: 1rem 1.5rem;
    margin-top: 4rem;
}

@media (min-width: 992px) {
    .partner-card {
        flex-direction: row;
        align-items: center;
    }
}

/* Responsive styles */
@media (max-width: 896px) {
    .partners-header {
        height: auto;
        padding: 20px 0;
    }

    .section-title {
        font-size: 2rem;
        margin-top: 5rem;
    }

    .btn-custom {
        margin-top: 20px;
    }

    .cta-section {
        padding: 40px 20px; /* Increase padding for better fit */
        height: auto; /* Adjust height */
      
    }

    .cta-title {
        font-size: 1.5rem; /* Adjust font size */
    }

    .cta-subtitle {
        text-align: left; /* Align text to the left */
        max-width: 100%; /* Make the text container wider on mobile */
        margin: 0 auto;
        font-size: 1rem; /* Adjust font size */
        padding: 0 1rem; /* Add padding */
        word-wrap: break-word; /* Ensure text wraps correctly */
    }
}