@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');

body, html {
  margin: 0;
  padding: 0;
  font-family: 'Inter', sans-serif; 
}

section {
  padding: 100px 0;
}

.section.bg-primary {
  background-color: #0a74da;
  color: #ffffff;
}

.section.bg-light {
  background-color: #f8f9fa; 
}

h1, h2, h3, h4 {
  font-family: 'Inter', sans-serif;
  font-weight: 700;
}
 
p {
  font-size: 1rem;
  line-height: 1.6;
  color: #4a4a4a;
}

button {
  font-family: 'Inter', sans-serif;
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

