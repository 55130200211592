.competition-section {
    padding: 50px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    margin-bottom: 6rem;
  }
  
  .image-wrapper {
    position: relative;
    display: inline-block;
  }

  .image-overlay {
    position: absolute;
    bottom: 30px; /*overlay offsets*/
    right: 100px;  /*overlay offsets*/
    width: 90%;
    height: 100%;
    background-color: #00e5ff;
    border-radius: 8px; 
    z-index: -1;
  }
  
  .competition-content {
    display: flex;
    align-items: flex-start; 
    justify-content: center;
    max-width: 1200px; 
    margin: 0 auto; 
    gap: 50px; 
    padding: 0 40px; 
  }
  
  .competition-image-container {
    flex: 1;
  }
  
  .competition-image {
    width: 90%; 
    border-radius: 8px; 
  }
  
  .competition-text {
    flex: 1.5; 
    text-align: left;
    padding-left: 30px; 
    max-width: 600px;
    margin-top: 50px;
  }
  
  .competition-title {
    font-size: 3rem; 
    font-weight: 800;
    margin-bottom: 20px;
    text-align: left;
  }
  
  .competition-description {
    line-height: 1.6;
    padding-right: 1.5rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  
  .read-more-btn {
    width: fit-content;
    text-decoration: none;
    background: #000;
    color: #fff; 
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    font-weight: 600;
    cursor: pointer;
    display: flex;
    margin-top: 50px;
    align-items: center;
    gap: 10px;
  }

  .read-more-btn .arrow {
    font-weight: bold;
    transition: margin-left 0.3s;
  }
  
  .read-more-btn:hover .arrow {
    margin-left: 5px; 
  }

/* Add responsive styles for CompetitionSection */
@media (max-width: 896px) {
  .competition-section {
    padding: 20px;
    text-align: center;
  }

  .competition-content {
    max-width: 100%;
    display: flex;
    flex-direction: column; /* Change flex direction to column for mobile */
    align-items: center;
  }

  .competition-title {
    font-size: 2rem;
  }

  .competition-description {
    font-size: 1rem;
  }

  .competition-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px; /* Add margin to separate the image from the paragraph */
    border: 1px solid #ccc; /* Add border to the image */
  }

  .image-overlay {
    position: absolute;
    bottom: 35px; /*overlay offsets*/
    right: 40px;  /*overlay offsets*/
    width: 90%;
    height: 90%;
    background-color: #00e5ff;
    border-radius: 8px; 
    z-index: -1;
  }
}