.teams-page {
  color: #ffffff;
}

/* Hero Section */
.hero-section {
  background-image: linear-gradient(rgba(0, 0, 0, 0.65), rgba(0, 0, 0, 0.65)), url("../assets/bg.jpg"); 
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  padding: 80px 0;
  text-align: center;
  height: 90vh;
  margin-top: 80px;
}

.hero-section-text {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.hero-section-text h1 {
  font-size: 3rem;
  font-weight: 800; 
}

.hero-section-text p {
  margin-top: 4rem;
  margin-bottom: 2.5rem;
  padding-left: 15%;
  padding-right: 15%;
  color: #fff;
}

.team-division {
  padding-left: 10%;
  padding-right: 10%;
  padding-top: 5%;
  padding-bottom: 7%;
  color: #ffffff;
}

.team-division h2 {
  font-size: 2.5rem;
  position: relative;
  display: inline-block;
}

.team-division h2 span {
  display: block;
  height: 4px;
  width: 50px;
  margin-top: 8px;
}

.team-division img {
  padding-left: 10%;
  padding-right: 15%;
  max-width: 100%;
  height: auto;
}

.team-division p {
  margin-top: 10%;
  margin-right: 20%;
}

/* Join Section */
.cta-footer {
  text-align: center;
  padding-top: 4%;
  padding-bottom: 4%;
  color: #ffffff;
  background-color: #e91185;
}

.cta-footer h2 {
  font-size: 1.75rem;
  margin-bottom: 10px;
}

.cta-button {
  background-color: black;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.cta-button:hover {
  background-color: #333333;
  color: white;
}


/* Responsive Styles */
@media (max-width: 896px) {
  .team-banner {
    padding: 20px 0; /* Adjust the padding to make the text higher */
    margin-top: 60px; /* Add margin-top to create space for the navbar */
  }

  .team-banner h1 {
    font-size: 2rem;
    margin-top: 20px; /* Add margin-top to ensure the text is not cut off */
  }

  .hero-section h1 {
    font-size: 2.5rem;
  }

  .team-division h2 {
    font-size: 1.5rem;
  }

  .team-division p {
    font-size: 0.875rem;
  }

}