.navbar-custom {
  background-color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 50px;
}

.navbar-brand-custom {
  color: #ffffff;
  font-size: 16px;
  font-weight: 1000;
  padding: 8px 20px;
  cursor: default;
  text-decoration: none;
}

.navbar-brand-custom:hover {
  color: #ffffff;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}


.navbar-logo {
  height: 70px;
  width: 70px;
  margin-right: 10px;
}


.nav-links {
  display: flex;
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 30px;
}

.nav-links .nav-link {
  color: #ffffff;
  font-size: 16px;
  font-weight: 700;
  text-decoration: none;
  font-family: 'Inter', sans-serif;
  transition: color 0.3s ease-in-out;
}

.nav-link.active {
  color: #00FFFF !important;
}

.nav-links .nav-link:hover {
  color: #ff69b4;
  text-decoration: none;
}

.join-btn {
  margin-left: 20px;
  padding: 10px 20px;
  background: transparent;
  border: 2px solid #00e5ff;
  border-radius: 5px;
  color: #00e5ff;
  font-weight: 800;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.3s ease-in-out;
}

.join-btn:hover {
  background-color: #00e5ff;
  color: #ffffff;
}

/* Responsive styles */
@media (max-width: 896px) {
  .navbar-custom {
    padding: 10px;
  }

  .navbar-brand-custom {
    flex-grow: 1;
  }

  .navbar-collapse {
    justify-content: flex-end;
    width: 100%;
    overflow-y: auto; /* Allow vertical scrolling */
    max-height: 70vh;
  }

  .nav-links {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .nav-links .nav-link {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .navbar-toggler {
    border: none;
    color: #ffffff;
  }

  .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
  }

}